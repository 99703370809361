@import '../../theme/colors.scss';

.offersWrapper {
  margin: 130px auto 0 auto;
}

///// cards

.cardStyle {
  margin: 100px auto;
  width: 80%;
  height: 840px;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.modalCard {
  @extend .cardStyle;
  height: auto !important;
  margin: 20px auto;
  display: block;
}

.thankYouCard {
  @extend .cardStyle;
  width: 100%;
  height: 280px !important;
}

.cardImg {
  padding: 15px;
}

.cardTitle {
  font-family: 'Parisienne', cursive;
  font-weight: 400;
  font-size: 45px;
  color: $mainColor;
  line-height: 53px;
  margin-top: 27px;
}

.description,
.daysSpan,
.priceSpan,
.smallText {
  font-family: 'Poppins', sans serif;
}

.description {
  font-weight: 300;
  font-size: 18px;
  line-height: 29px;
}

.smallerDescription {
  font-size: 16px;
}

.boldText {
  font-weight: 600;
  color: $mainColor;
}
.smallText {
  font-size: 14px;
}

@media only screen and (max-width: 1500px) {
  .cardStyle {
    height: 90vh;
  }
  .cardTitle {
    font-size: 35px;
    padding-bottom: 10px;
  }
}

@media only screen and (min-width: 961px) {
  .cardStyle {
    height: 95vh;
  }
  .cardTitle {
    font-size: 35px;
    padding-bottom: 10px;
  }
}

@media only screen and (max-width: 450px) {
  .description {
    font-size: 16px;
  }
}
