@import '../../theme/colors.scss';

.formContainer {
  padding-bottom: 80px;
  margin-top: 50px;
}

.formWrapper {
  padding: 0 70px 0 70px;
  width: auto;
  height: auto;
}

.formInputs,
.formTextArea {
  display: block;
  margin: 0 auto;
  height: 55px;
  border-radius: 10px;
}

.formTextArea {
  height: 130px;
}

.formLabels {
  display: block;
  margin-left: 95px;
  font-size: 20px;
  text-align: left;
  color: $mainColor;
  background-color: $white;
  position: relative;
  top: 30px;
  left: -30px;
  width: 100px;
  padding-left: 15px;
  z-index: 1;
}

.formBorder {
  position: relative;
  background: linear-gradient(35deg, $mainColor, $lightGradient);
  padding: 3px;
  margin: 20px;
  border-radius: 10px;
}

.formInputs,
.formTextArea {
  font-size: 18px;
  color: $textColor;
}

.formTextArea:placeholder-shown {
  font-size: 16px;
  color: $placeholderColor;
  font-weight: bold;
  font-style: oblique;
}

.form-control:focus {
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.inputLine {
  margin-top: -40px;
}

.checkbox {
  margin: 15px 10px 0 40px;
  background-color: $checkboxBackground;
  width: 32px;
  height: 30px;
  padding: 4px;
  border-radius: 5px;
  float: left;
  &:hover {
    cursor: pointer;
  }
}

.formBtn {
  margin: -10px 20px;
  width: -webkit-fill-available;
  height: 55px;
  border-radius: 10px;
  font-size: 25px;
  background: linear-gradient(180deg, $lightGradient 0%, $mainColor 100%);
  box-shadow: 0px 4px 4px $textShadow;
  border: none;
}

.btnWrapper {
  display: block;
  margin: 0 auto;
  align-items: center;
  width: 920px;
}

/* form button  */

.formBtn {
  display: inline-block;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

.formBtn:hover,
.formBtn:focus,
.formBtn:active {
  background: $white !important;
  color: $mainColor !important;
  border: 4px solid $mainColor !important;
  box-shadow: none !important;
}

/* rezerve button */

.reserveBtnLeft,
.reserveBtnRight {
  width: 300px;
  height: auto;
  transition: transform 0.2s;
}

.reserveBtnRight {
  margin-left: -120px;
}

.reserveBtnLeft {
  margin-right: -120px;
}

.reserveBtnRight:hover,
.reserveBtnLeft:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.submitMessageStyle {
  display: block;
  margin: 40px auto;
  text-align: center;
  width: 50vw;
}

.newsletterText {
  margin-left: 40px;
  padding: 15px;
  color: $darkerMainColor;
  font-size: 20px;
}

//// responsive

@media only screen and (max-width: 600px) {
  .formContainer {
    margin-top: 20px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .formWrapper {
    padding: 0px;
  }
}

@media only screen and (max-width: 999px) {
  .formContainer {
    padding-bottom: 0;
  }
  .btnWrapper {
    width: auto;
  }
  .newsletterText {
    font-size: 18px;
  }
  .reserveBtnLeft,
  .reserveBtnRight {
    width: 50%;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 1000px) {
  .formContainer {
    padding-bottom: 0;
  }
  .reserveBtnLeft,
  .reserveBtnRight {
    width: 50%;
    margin: 10px auto;
  }
  .reserveBtnRight {
    margin-left: 0;
  }

  .reserveBtnLeft {
    margin-right: 0;
  }
  .newsletterText {
    font-size: 18px;
  }
}
