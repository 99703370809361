@import '../.././theme/colors.scss';

.galleryCard {
  margin-top: 80px;
  width: 800px;
}

.galleryContainer {
  width: 1600px !important;
  margin: 50px auto;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  border-radius: 9px;
  padding-top: 50px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.galleryCard {
  margin: 20px auto;
  width: 1200px;
  align-items: center;
  padding-bottom: 50px;
}

@media only screen and (max-width: 1600px) {
  .galleryContainer {
    width: 90% !important;
    height: auto;
  }
}

@media only screen and (max-width: 1000px) {
  .galleryContainer {
    width: 90% !important;
    height: auto;
  }
}
