@import '../../../../theme/colors.scss';
@import '../../../../theme/breakpoints.scss';

.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.toolbar_navigator {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
}

.toolbar_logo {
  margin-left: 1rem;
}

.menu {
  padding-top: 0;
}

.menuBtn:hover {
  color: $mainColor;
}

.stickyToolbarLogo {
  width: 70px;
}

.toolbar_logo a {
  color: $white;
  text-decoration: none;
  font-size: 1.5rem;
}

.spacer {
  flex: 1;
}

.toolbar_navigation-items ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbar_navigation-items a {
  text-decoration: none;
}

.title-desc-wrapper {
  margin-left: 20vh;
  margin-top: 5vh;
}

@media screen and (max-width: 1350px) {
  .toolbar_navigation-items {
    display: none;
  }
}

@media screen and (min-width: 1350px) {
  .toggle-btn {
    display: none;
  }

  .toolbar_logo {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 750px) {
  .title-desc-wrapper {
    margin-top: -20px;
    margin-left: 1vh;
  }
  .button-main-color {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 900px) {
  .stickyToolbarLogo {
    width: 80px;
  }
  .title-desc-wrapper {
    margin-left: 2vh;
  }
}

@media only screen and (max-width: 1350px) {
  .menu {
    padding-top: 0px !important;
  }
  .toolbar_logo {
    margin-top: 20px;
    margin-bottom: 20px;
    width: -webkit-fill-available;
  }
  .title-desc-wrapper {
    margin-left: 5vh;
  }
}
