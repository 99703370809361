@import '../.././theme/colors.scss';

.paragraphCard {
  position: relative;
  margin: -20px;
  border-radius: 20px;
}

.cardBorder {
  padding: 30px;
  background: linear-gradient(
    224.39deg,
    var($white) 54.39%,
    var($cardBackground) 106.5%
  );
  border-radius: 20px;
}
#despre-noi {
  margin-top: 210px;
}

.paragraphImg {
  width: 100%;
}

@media only screen and (max-width: 400px) {
  .paragraphImg {
    margin-top: -20vh;
  }
}
