.gallery-card {
  margin-left: 10px;
  margin-right: 10px;
}

.gallery-portrait-photo {
  width: 300px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}

.portrait-photo-right {
  position: absolute;
  left: 40px;
}

.gallery-wide-photo {
  width: 350px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}

.wide-pic-1 {
  position: absolute;
  margin-bottom: 2px;
  left: 0px;
  top: -40px;
}
.wide-pic-2 {
  position: absolute;
  left: 0px;
  bottom: -20px;
}
.mobile-galleryCard {
  display: none;
}

@media only screen and (max-width: 1300px) {
  .wide-pic-1 {
    height: 50%;
  }
  .wide-pic-2 {
    height: 50%;
  }
}

@media only screen and (max-width: 1000px) {
  .gallery-portrait-photo {
    width: 200px;
    margin-left: 20px;
  }
  .gallery-wide-photo {
    margin-right: 20px;
  }

  .portrait-photo-right {
    left: -310px;
  }
  .wide-pic-1,
  .wide-pic-2 {
    height: auto;
    width: 200px;
    left: -130px;
  }
}

@media only screen and (max-width: 800px) {
  .gallery-portrait-photo {
    width: 190px;
  }
  .portrait-photo-right {
    left: -350px;
  }
  .wide-pic-1,
  .wide-pic-2 {
    left: -155px;
  }
}

@media only screen and (max-width: 700px) {
  .gallery-portrait-photo {
    width: 150px;
    margin-left: 20px;
  }

  .portrait-photo-right {
    left: -389px;
  }

  .gallery-wide-photo {
    margin-right: 20px;
  }
  .wide-pic-1,
  .wide-pic-2 {
    width: 150px;
    left: -170px;
  }
}
