@import '../../../theme/colors.scss';

.first-section-background {
  position: relative;
  height: 114vh;
  background: url('../../../images/Dark overlay-min.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.booking-button {
  border: 1px solid $white;
  box-sizing: border-box;
  border-radius: 43px;
}

.booking-button:hover {
  border: 1px solid $mainColor;
  background-color: $white;
}

@media only screen and (max-width: 700px) {
  .first-section-background {
    width: 100vw;
    height: 80vh;
  }
}

.bannerDiv {
  position: absolute;
  display: block;
  margin: 0 auto;
  top: 25vh;
  left: 38vw;
}

.valentinesBannerText {
  text-transform: uppercase;
  text-align: center;
}

.valentinesBannerBtn {
  display: block;
  margin: 0 auto;
  text-decoration: underline;
  text-align: center;
  color: $textColor;
  font-weight: normal;
  &:hover {
    cursor: pointer;
    text-decoration: none;
    color: $iconsTitle;
  }
}

@media only screen and (min-width: 400px) {
  .bannerDiv {
    top: 17vh;
    left: 12vw;
    width: 314px;
  }
  .valentinesBannerText {
    font-size: 16px;
  }
  .valentinesBannerBtn {
    font-size: 16px;
  }
}

@media only screen and (min-width: 500px) {
  .bannerDiv {
    left: 2vw;
    width: 500px;
  }
  .valentinesBannerText {
    font-size: 16px;
  }
  .valentinesBannerBtn {
    font-size: 16px;
  }
}

@media only screen and (min-width: 600px) {
  .bannerDiv {
    left: 9vw;
  }
}

@media only screen and (min-width: 700px) {
  .bannerDiv {
    left: 16vw;
    top: 24vh;
  }
  .valentinesBannerText {
    font-size: 20px;
  }
  .valentinesBannerBtn {
    font-size: 20px;
  }
}

@media only screen and (min-width: 800px) {
  .bannerDiv {
    left: 17vw;
    top: 25vh;
  }
  .valentinesBannerText {
    font-size: 20px;
  }
  .valentinesBannerBtn {
    font-size: 20px;
  }
}

@media only screen and (min-width: 900px) {
  .bannerDiv {
    left: 22vw;
    top: 25vh;
  }
}

@media only screen and (min-width: 1000px) {
  .bannerDiv {
    left: 26vw;
    top: 25vh;
  }
}

@media only screen and (min-width: 1200px) {
  .bannerDiv {
    top: 25vh;
    left: 37vw;
  }
}
