@import '../../theme/colors.scss';

.iconsWrapper {
  margin-top: 126px;
}

/* //SingleIcons   */

.iconsCard {
  margin: 40px auto;
  width: auto;
  height: 250px;
  background-color: $white;
  transition: all 0.3s ease;
  position: relative;
  align-items: center;
  transition: all 0.3s ease;
}

.icon {
  margin: 0 auto;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.8s ease;
}

.iconTitle {
  font-family: Poppins;
  font-weight: 600;
  font-size: 22px;
  line-height: 45px;
  color: $iconsTitle;
}

.iconsCard .iconTitle {
  width: auto;
  margin: 0;
  text-align: center;
  margin-top: 30px;
  color: $mainColor;
  font-weight: 600;
  letter-spacing: 2px;
}

.iconDescription {
  margin: 0 auto;
  width: auto;
}

.iconsCard .iconDescription {
  color: $textColor;
  font-size: 18px;
  line-height: 36px;
  width: auto;
  padding: 10px;
  text-align: center;
  margin-top: 20px;
  font-weight: 400;
  letter-spacing: 2px;
  opacity: 0;
  max-height: 0;
  transition: all 0.3s ease;
}

.iconsCard:hover {
  height: 300px;
  width: auto;
}

.iconsCard:hover .iconDescription {
  transition: all 0.3s ease;
  opacity: 1;
  max-height: initial;
  padding: 20px;
  background: $white;
  border-radius: 20px;
  height: auto;
}

.descriptionBorder {
  background: $white;
}
.iconsCard:hover .descriptionBorder {
  position: relative;
  background: linear-gradient(224.39deg, $white 80.39%, $mainColor 100.5%);
  padding: 3px;
  margin: 35px;
  border-radius: 20px;
  width: auto;
}

.iconsCard:hover .icon {
  background-position: -220px;
  transition: all 0.3s ease;
  opacity: 0;
  margin-top: -150px;
}

@media only screen and (max-width: 900px) {
  .iconsWrapper {
    margin-bottom: 50px;
  }

  .iconsWrapper:hover {
    margin-bottom: 100px;
  }

  .iconsCard {
    margin-top: 40px auto;
    padding-top: 20px;
    margin-bottom: 90px;
  }

  .iconsCard:hover {
    height: auto;
  }

  .icon:hover {
    margin-top: 50px;
  }
  .iconDescription {
    font-size: 18px;
    line-height: 20px;
  }

  .iconsCard:hover .descriptionBorder {
    background: linear-gradient(224.39deg, $white 89.39%, $mainColor 100.5%);
  }
}

@media only screen and (min-width: 1000px) {
  .iconsCard:hover .descriptionBorder {
    background: linear-gradient(224.39deg, $white 92.39%, $mainColor 100.5%);
  }
}
