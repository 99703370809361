@import '../.././theme/colors.scss';

.lineWrapper {
  display: block;
  margin-top: -10px;
}

.line {
  padding-top: 0;
}

@media only screen and (min-width: 300px) {
  .line {
    width: 100px;
  }
}
@media only screen and (min-width: 600px) {
  .line {
    margin-top: -25px;
    width: 250px;
  }
}
@media only screen and (min-width: 961px) {
  .line {
    margin-top: -25px;
    width: 250px;
  }
}
