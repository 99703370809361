@import '../../theme/colors.scss';

.headingImg {
  display: block;
  margin: 0 auto 30px auto;
  align-items: center;
}

.heading {
  color: $mainColor;
  font-weight: bold;
  font-size: 50px;
  line-height: 52px;
  text-align: center;
  text-shadow: 0px 4px 4px $textShadow;
  margin-bottom: 10px;
  padding: 30px;
}

.italicHeading {
  font-family: 'Parisienne', cursive;
  font-weight: 500;
  font-size: 70px;
}

@media only screen and (max-width: 900px) {
  .heading {
    font-size: 26px;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  .italicHeading {
    font-size: 40px;
  }
  .headingImg {
    margin-top: 30px;
    width: 340px;
    height: auto;
    padding-bottom: 20px;
  }
}

@media only screen and (min-width: 901px) {
  .heading {
    margin: 20px 0 20px 0;
  }
  .horizontalLine {
    margin-bottom: 40px;
  }
  .headingImg {
    margin-top: 30px;
    width: 340px;
    height: auto;
  }
}

@media only screen and (min-width: 320px) {
  .headingImg {
    margin-top: 30px;
    width: 300px;
    height: auto;
    padding-right: 15px;
  }
}
