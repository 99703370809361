@media only screen and (max-width: 900px) {
  .copyrights {
    font-size: 12px;
    text-align: center;
    padding: 0px;
    margin: 0;
  }
}

@media only screen and (min-width: 901px) {
  .copyrights {
    margin: 0;
    font-size: 10px;
    text-align: center;
  }
}

@media only screen and (min-width: 1000px) {
  .copyrights {
    font-size: 14px;
    text-align: center;
  }
}
