@import '../../theme/colors.scss';

.mapSectionWrapper {
  margin-top: 100px;
}

.placeTitle {
  color: $mainColor;
  font-size: 20px;
  font-weight: 600;
}

.placeAddress {
  color: $textColor;
  font-size: 18px;
  font-weight: 500;
  font-style: oblique;
}

.mapKeys {
  margin-top: 160px;
}

.mapKeyWrapper {
  margin: 40px;
}

.keyIcon {
  display: inline;
  margin: 0 auto;
  align-items: center;
  padding: 20px;
}

.keyTitle {
  display: inline;
  margin-left: 50px;
  align-items: left;
  color: $mainColor;
  font-family: Poppins;
  font-size: 34px;
}
#localizare {
  margin-bottom: 60px;
}

@media only screen and (max-width: 900px) {
  .keyTitle {
    font-size: 20px;
    margin-left: 0;
  }
  .keyIcon img {
    margin-left: 60px;
    height: 30px;
  }
}
@media only screen and (max-width: 600px) {
  .mapSectionWrapper {
    margin-top: 80px;
  }
  .keyTitle {
    font-size: 16px;
    margin-left: 0;
  }
  .keyIcon {
    margin-left: 10px;
  }
}

@media only screen and (min-width: 901px) {
  .mapKeys {
    margin: 0 auto;
    padding-left: 240px;
  }
  .keyTitle {
    font-size: 24px;
  }
}

@media only screen and (min-width: 1000px) {
  .mapKeys {
    margin: 0 auto;
    padding: 0;
    margin-top: 170px;
  }
  .keyTitle {
    font-size: 20px;
  }

  .keyIcon img {
    width: 10%;
    margin-left: 20px;
  }
}

@media only screen and (min-width: 1200px) {
  .keyIcon img {
    width: auto;
  }
}
