button.BrainhubCarousel__arrows {
  background-color: initial;
  &:hover {
    background-color: transparent !important;
  }
}
.carousel-wrapper-outer {
  max-width: 70%;
  margin: 0 auto;
}
@media only screen and (max-width: 600px) {
  .carousel-wrapper-outer {
    max-width: 100%;
  }
}
