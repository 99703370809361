@import '../../theme/colors.scss';

.paragraph {
  padding: 40px;
  margin-top: 20px;
  font-weight: 400;
  font-size: 20px;
  color: $textColor;
  line-height: 55px;
  text-align: left;
  letter-spacing: 0.01em;
}

@media only screen and (max-width: 900px) {
  .paragraph {
    margin-left: 10px;
    font-size: 18px;
    line-height: 30px;
    padding: 30px;
    text-align: center;
    width: auto;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 425px) {
  .paragraph {
    width: auto;
  }
}

@media only screen and (min-width: 901px) {
  .paragraph {
    margin: 0 auto;
    font-size: 20px;
    line-height: 30px;
    padding: 30px;
    width: 650px;
    text-align: center;
  }
}
@media only screen and (min-width: 1000px) {
  .paragraph {
    margin: 40px;
    font-size: 20px;
    line-height: 35px;
    text-align: left;
    width: auto;
  }
}
@media only screen and (min-width: 1400px) {
  .paragraph {
    margin-top: 30px;
    font-size: 1.4rem;
    line-height: 40px;
  }
}

@media only screen and (min-width: 1700px) {
  .paragraph {
    margin-top: 30px;
    font-size: 1.4rem;
    line-height: 60px;
  }
}
