.side-drawer {
	height: 100%;
	background: linear-gradient(180deg, rgba(157, 119, 120) -49.73%, #e5e2e1 160.73%, #eec8b8 160.73%);
	box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	width: 70%;
	max-width: 400px;
	z-index: 200;
	transform: translateX(-100%);
	transition: transform 0.3s ease-out;
}

.side-drawer.open {
	transform: translateX(0);
}

.side-drawer ul {
	height: 100%;
	list-style: none;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

/* .side-drawer li {
	margin: 0.5rem 0;
} */

.side-drawer a {
	text-decoration: none;
	color: white;
	font-size: 1.2rem;
}

.side-drawer a:hover,
.side-drawer a:active {
	color: #90635e;
}

@media screen and (min-width: 1350px) {
	.side-drawer {
		display: none;
	}
}
