@import '../../../theme/colors.scss';

.footer-wrapper {
  padding-top: 70px;
  padding-left: 55px;
  padding-right: 55px;
  padding-bottom: 70px;
}
.bold-white {
  font-weight: 700;
}
.white-heading-shadow {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 104.83%;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: $white;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
}
a {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 168%;
  color: $white;
}
.footer-menu-item {
  display: block;
}
.footer {
  background: linear-gradient(
    39.79deg,
    rgba(144, 99, 94, 0.5832) 45.71%,
    rgba(144, 99, 94, 0) 122.56%
  );
}
.sm-cion {
  width: 40px;
  height: 40px;
  margin: -15px;
  &:hover {
    opacity: 0.6;
  }
}

.sm-cionDark {
  display: block;
  margin: 0 auto 20px auto;
  justify-content: space-between;
  align-items: center;
}

.footer-logo {
  margin: 0 auto;
  display: block;
  width: 50%;
}

@media only screen and (min-width: 300px) {
  .p-white-thin {
    font-size: 16px;
  }
}

@media only screen and (max-width: 900px) {
  .white-heading-shadow {
    font-size: 20px;
  }

  .footer-menu-item {
    font-size: 16px;
  }
  .footer-logo {
    width: 200px;
    padding: 30px;
  }
  .sm-cion {
    padding: 6px;
  }
}

@media only screen and (min-width: 901px) {
  .footerMenu {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 1200px) {
  .sm-cion {
    justify-content: space-between;
    padding: 6px;
    margin-left: -5px;
  }
}
.footer-menu-row {
  padding-left: 15%;
}
.logo-footer {
  margin-top: 30px;
  margin-bottom: 30px;
}
