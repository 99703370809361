$mainColor: #90635e;
$darkerMainColor: #9d7778;
$textColor: #4f4e4e;
$placeholderColor: #8a898998;
$textShadow: rgba(0, 0, 0, 0.25);
$lightGradient: #eec8b8;
$cardBackground: rgba(157, 119, 120, 0.62);
$white: #fff;
$cardBackground: rgba(157, 119, 120, 0.62);
$checkboxBackground: #e5e2e1;
$iconsTitle: #9d7778;
$black: #000;
