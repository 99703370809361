@import '../../../theme/colors.scss';

// .menu {
//   padding-top: 20px;
//   padding-bottom: 20px;
// }

.sticky {
  top: 0;
  right: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(157, 119, 120) -49.73%,
    #e5e2e1 160.73%,
    #eec8b8 160.73%
  ) !important;
  margin: 0 auto;
  z-index: 2;
  padding-top: 10px;
  padding-bottom: 10px;
}
.sticky-logo img {
  height: 30px;
}

.gradient-underline-menu-item {
  position: relative;
  display: block;
  padding: 5px 0;
  line-height: 16px;
  transition: color 0.1s, background-color 0.1s, padding 0.2s ease-in;
  color: $mainColor;
}
.gradient-underline-menu-item::before {
  content: '';
  display: block;
  position: absolute;
  bottom: 3px;
  left: 0;
  height: 3px;
  width: 100%;
  background-color: $mainColor;
  transform-origin: right top;
  transform: scale(0, 1);
  transition: color 0.1s, transform 0.2s ease-out;
}
.gradient-underline-menu-item:hover,
.gradient-underline-menu-item:focus,
.gradient-underline-menu-item:active {
  color: $mainColor;
  text-decoration: none;
}
.gradient-underline-menu-item:active::before {
  background-color: $mainColor;
}
.gradient-underline-menu-item:hover::before,
.gradient-underline-menu-item:focus::before {
  transform-origin: left top;
  transform: scale(1, 1);
}

.menuBtn:hover {
  color: $mainColor;
}

@media only screen and (max-width: 1350px) {
  .sticky {
    height: 50px;
    padding-bottom: 0;
  }
  .sticky-logo img {
    height: auto;
    width: 80px;
  }
}
