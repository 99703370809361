button.BrainhubCarousel__arrows {
  background-color: initial;
  &:hover {
    background-color: initial;

    span {
      border-color: #90635e !important;
    }
  }
  span {
    border-color: #90635e;
  }
}
.gallery-container {
  background: linear-gradient(
    180deg,
    rgba(157, 119, 120, 0.59) -49.73%,
    #e5e2e1 160.73%,
    #eec8b8 160.73%
  );
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 9px;
  padding-top: 50px;
}

@media only screen and (max-width: 900px) {
  .gallery-container {
    margin-top: 40px;
  }
  .galleryDiv {
    margin-top: -130px;
    margin-bottom: 100px;
  }
}

@media only screen and (min-width: 901px) {
  .gallery-container {
    margin-top: 40px;
  }

  .galleryDiv {
    margin-top: 80px;
    padding: 60px 0;
    margin-bottom: 40px;
  }
}
