@import '../../../theme/colors.scss';

.carousel-wrapper {
  padding: 1rem;
  position: relative;
  background: linear-gradient(to right, $lightGradient, $mainColor);
  padding: 3px !important;
  border-radius: 20px;
  width: auto;
  margin-left: 5px;
  margin-right: 5px;
}
.carousel-content {
  background: $white;
  color: $textColor;
  padding: 2rem !important;
  margin-right: 0px;
  margin-left: 0px;
  padding-left: 5px !important;
  padding-right: 5px !important;
  border-radius: 20px;
}

.testimonial-picture {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  margin: 15px;
}
.testimonial-picture-wrapper {
  background-image: url('../../../images/Vector.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}
.star-ratings {
  margin-top: 25px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 900px) {
  .carousel-inner-container {
    margin-top: -50px;
  }
}
@media only screen and (min-width: 300px) {
  .carousel-wrapper {
    margin-top: 60px !important;
  }
}
