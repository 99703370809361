@import './theme/colors.scss';

#root {
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.BrainhubCarousel__dots .BrainhubCarousel__dot:before {
  background: $mainColor !important;
  opacity: 0.5;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
}

html {
  scroll-behavior: smooth;
}

.BrainhubCarousel__arrows:hover:disabled {
  background-color: none !important;
}

/*Containers*/
.boxed-content-wide {
  max-width: 90%;
  margin: 0 auto;
}
.boxed-content-stretched {
  max-width: 80%;
  margin: 0 auto;
}
/*Buttons*/
.button-main-color {
  border-radius: 43px;
  color: $mainColor;
  text-decoration: none;
  width: 250px;
  height: 60px;
  margin-top: 75px;
  background: linear-gradient(180deg, $lightGradient 0%, $darkerMainColor 100%);
  color: $white;
  &:hover {
    background: $white;
    border: 1px solid $mainColor;
  }
}

.gradient-underline-button {
  padding-bottom: 4px;
  position: relative;
  text-decoration: none;
}
.gradient-underline-button:active {
  background: #f00;
  background: linear-gradient(
    to right,
    transparent 0%,
    #f00 50%,
    transparent 100%
  );
  content: '';
  height: 2px;
  width: 100%;
}
.logo {
  height: 150px;
}
/* *Fonts* */
a:hover {
  color: $mainColor;
}
a:hover {
  text-decoration: none;
}
.p-white-thin {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 168%;
  color: $white;
}

.p-black-small {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: $textColor;
}

.p-white-font {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 168%;
  color: $white;
}

.p-white-font-big {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  display: flex;
  align-items: center;
  text-align: center;
}

.p-white-thin {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  color: rgba(255, 255, 255);
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
}

.p-testimonial-font {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 34px;
  display: flex;
  align-items: center;
  text-align: center;
  color: $textColor;
}

.signature-font {
  font-family: Parisienne;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 168%;
  color: $textColor;
}

.white-big-heading {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 129%;
  letter-spacing: 0.055em;
  color: $white;
  text-shadow: 0px 4px 4px $textShadow;
}

.italic-desc-big {
  font-family: Parisienne;
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  line-height: 117.16%;
  letter-spacing: 0.01em;
  color: $white;
  text-shadow: 0px 4px 4px $textShadow;
  margin-top: 10px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: none !important;
}

@media only screen and (max-width: 1500px) {
  .white-big-heading {
    font-size: 50px;
  }
}

@media only screen and (max-width: 1445px) {
  #map {
    width: auto !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}

@media only screen and (min-width: 1350px) {
  .button-main-color {
    width: 200px;
    height: 60px;
  }
}

@media only screen and (max-width: 1349px) {
  .italic-desc-big {
    font-size: 30px;
  }
}

@media only screen and (min-width: 961px) {
  .white-big-heading {
    // margin-top: 35vh;
    font-size: 40px;
  }

  .button-main-color {
    width: 220px;
    height: 60px;
    margin-top: 10px;
  }

  .p-white-font-big {
    font-size: 20px;
  }
}

@media only screen and (max-width: 960px) {
  .mapKeys {
    margin-top: 20px !important;
  }
  .white-big-heading {
    margin-top: 33vh;
    margin-bottom: 1vh;
    font-size: 22px;
  }

  .italic-desc-big {
    padding-bottom: 2vh;
  }

  .button-main-color {
    margin-top: 10px;
    font-size: 16px;
    width: 178px;
    height: 48px;
  }
}

@media only screen and (max-width: 700px) {
  .p-white-font-big {
    font-size: 18px;
  }

  .white-big-heading {
    margin-top: 27vh;
    margin-bottom: 1vh;
    font-size: 18px;
  }

  .italic-desc-big {
    font-size: 20px;
    padding-bottom: 10vh;
  }

  .button-main-color {
    margin-top: -40px;
    width: 178px;
    height: 48px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 551px) {
  .mapKeyWrapper {
    margin: 10px !important;
  }
}

@media only screen and (max-width: 480px) {
  .p-black-small {
    font-size: 18px;
  }
  .p-white-font {
    font-size: 16px;
  }
  .p-white-thin {
    font-size: 16px;
  }

  .p-white-font-big {
    font-size: 18px;
  }
  .signature-font {
    font-size: 18px;
  }
  .p-testimonial-font {
    font-size: 16px;
    line-height: 20px;
    height: 80%;
  }
}
